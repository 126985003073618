import { useWindowSize } from "../lib/hooks/useWindowSize";
import { UnderLine } from "./../components/UnderLine";

export const CustomerConfirmationContent = () => {
  const { width: responsiveWidth, height: responsiveHeight } = useWindowSize();

  return (
    <div
      style={{
        textAlign: "left",
        padding:
          responsiveWidth < 730 ? "5px 20px 0px 20px" : "20px 40px 10px 40px",
        border: "1px solid gray",
        fontWeight: "bold",
        lineHeight: "1.0",
      }}
    >
      <ol>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9312; 本サービスは
          <UnderLine>
            ビデオ通話にてご相談を受付いたします為、店舗へのご来店は不要
          </UnderLine>
          です。
          <br />
          <UnderLine>画面を投影しながらご案内</UnderLine>を行います。
        </li>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9313; ご利用のスマートフォン、PCのスペック等により
          一部ご利用いただけない場合がございます。
          <br />
          接続環境の良い場所でご利用ください。
        </li>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9314; 本サービスのご利用は無料です。
          <br /> ただし
          <UnderLine>通信費はお客さま負担</UnderLine>
          となります為、ご自宅のWi-Fi環境への接続、
          無制限プランでのご利用を推奨いたします。
        </li>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9315;
          ビデオ通話をご利用されるのは、ご契約者ご本人さま(成人)に限ります。
        </li>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9316;
          ご予約の日時となりましてもご案内のオンラインURLからのアクセスが
          確認できない場合、ご予約のキャンセル、または他のお客さまを優先し
          ご案内する場合がございます。
        </li>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9317; オンライン窓口でご相談後、
          <UnderLine>通信契約･機種購入や各種お手続きを行う場合、</UnderLine>
          <UnderLine>ご来店でのお手続きをご案内</UnderLine>いたします。
        </li>
        <li
          style={{
            fontSize: responsiveWidth < 730 ? "13px" : "15px",
            lineHeight: responsiveWidth < 730 ? "1.4" : "1.8",
          }}
        >
          &#9318; サービス品質向上のために録画させていただきます。
          <br />なお音声データのみ保存させていただきます。画像データは保存いたしません。
        </li>
      </ol>
    </div>
  );
};
