import "../App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import React, { useState } from "react";
import Modal from "react-modal";
import { useWindowSize } from "../lib/hooks/useWindowSize";
import { CustomerConfirmationContent } from "../data/CustomerConfirmationContent";

Modal.setAppElement("#root");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("jp-JP"); // ここでUIの言語が変更できる
ZoomMtg.i18n.reload("jp-JP"); // ここでUIの言語が変更できる

function ZoomMeetingOld(props) {
  const [modalButtonColor, setModalButtonColor] = useState("#2d8cff");
  const { width: responsiveWidth, height: responsiveHeight } = useWindowSize();
  const largeModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 490 ? "510px" : `${responsiveHeight}px`,
      width: "1000px",
      top: "52%",
      left: "50%",
      zIndex: 1,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const middleModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 490 ? "580px" : `${responsiveHeight}px`,
      width: "700px",
      top: "52%",
      left: "50%",
      zIndex: 1,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const mobileModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 390 ? "615px" : `${responsiveHeight}px`,
      width: "350px",
      top: "50%",
      left: "50%",
      right: "auto",
      zIndex: 1,
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };
  // iPhone8用の、モーダルスタイル
  const smallMobileModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: "480px",
      width: "350px",
      top: "50%",
      left: "50%",
      right: "auto",
      zIndex: 1,
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const [modalIsOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    // ボタン押下時の色を変えてすぐに元に戻す
    setModalButtonColor("#91c2ff");
    setTimeout(() => {
      setModalButtonColor("#2d8cff");
    }, 190);
    // モーダルを閉じる
    setIsOpen(false);
  };
  const responsiveModalStyles = () => {
    if (responsiveWidth > 1000) {
      return largeModalStyles;
    }
    if (responsiveWidth > 730) {
      return middleModalStyles;
    }
    if (responsiveHeight > 580) {
      return mobileModalStyles;
    }
    if (responsiveHeight < 580) {
      return smallMobileModalStyles;
    }
    return largeModalStyles;
  };

  const meetingAttended = localStorage.getItem("meetingAttended"); // ミーティングに参加した記録をlocalStorageから取得;
  return (
    <>
      <div style={{ width: "660px" }}>
        <Modal isOpen={modalIsOpen} style={responsiveModalStyles()}>
          {!meetingAttended ? (
            <>
              <div
                style={{
                  backgroundColor: "#0070c0",
                  color: "#ffffff",
                  height: "50px",
                  width: "100%",
                  textAlign: "center",
                  padding: "12px 0 0 0",
                }}
              >
                <h1
                  style={{
                    color: "#ffffff",
                    margin: responsiveWidth < 735 ? "2px 0 0 0" : "0 0 0 0",
                    fontSize: responsiveWidth < 735 ? "19.5px" : "24px",
                  }}
                >
                  オンライン窓口ご利用に伴うご案内
                </h1>
              </div>
              <div
                style={{
                  height: "50px",
                  width: "100%",
                  textAlign: "center",
                  padding: "12px 0 0 0",
                }}
              >
                <h2
                  style={{
                    color: "#0070c0",
                    margin: "0 0 0 0",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
                  下記内容のご承諾をお願い致します。
                </h2>
              </div>
              <div
                style={{
                  marginTop: "-25px",
                  padding:
                    responsiveWidth < 730
                      ? "20px 20px 20px 20px"
                      : "20px 40px 20px 40px",
                }}
              >
                <CustomerConfirmationContent />
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              >
                <button
                  className="modal-button"
                  onClick={() => closeModal()}
                  onKeyDown={closeModal}
                  tabIndex={0}
                  style={{
                    textAlign: "center",
                    cursor: "pointer",
                    width: "150px",
                    height: "35px",
                    borderRadius: "5px",
                    color: "#ffffff",
                    margin: "0 0 20px 0",
                    backgroundColor: modalButtonColor,
                  }}
                >
                  OK
                </button>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  margin: "5px 15px 0px 0px",
                  float: "right",
                  cursor: "pointer",
                  height: "25px",
                  width: "25px",
                  fontWeight: "bold",
                }}
                onClick={closeModal}
              >
                <p
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  ✕
                </p>
              </div>
              <div
                className="thanks-page"
                style={{ marginTop: responsiveWidth < 600 ? "-35px" : "0px" }}
              >
                <h1>
                  様<p>お問い合わせありがとうございました。</p>
                </h1>
              </div>
              <div
                style={{
                  textAlign: "center",
                }}
              ></div>
            </>
          )}
        </Modal>
      </div>
      {/* 一部モバイル端末で入力決定ボタンがポップアップキーボードに重なってしまうため、モバイル時のみ入力欄領域を上に上げる */}
      <main style={{ marginTop: responsiveWidth < 600 ? "-35px" : "0px" }}>
        <h1>ミーティング情報を入力する</h1>
        <form>
          <p>パスワード</p>
          <input defaultValue="" type="password"></input>
          <br />
          <button>確認</button>
        </form>
      </main>
    </>
  );
}

export default ZoomMeetingOld;
