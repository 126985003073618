import "../App.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import React, { useState } from "react";
import Modal from "react-modal";
import { useWindowSize } from "../lib/hooks/useWindowSize";
import { Loading } from "./Loading";

Modal.setAppElement("#root");
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load("jp-JP"); // ここでUIの言語が変更できる
ZoomMtg.i18n.reload("jp-JP"); // ここでUIの言語が変更できる

function ThxPage(props) {
  const { width: responsiveWidth, height: responsiveHeight } = useWindowSize();
  const encodedUserName = props.match.params.user_name
    .replace(/-/g, "+")
    .replace(/_/g, "/");
  const decodedUserName = decodeURIComponent(escape(atob(encodedUserName)));

  const largeModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 490 ? "510px" : `${responsiveHeight}px`,
      width: "1000px",
      top: "52%",
      left: "50%",
      zIndex: 1,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const middleModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 490 ? "580px" : `${responsiveHeight}px`,
      width: "700px",
      top: "52%",
      left: "50%",
      zIndex: 1,
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };

  const mobileModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: responsiveHeight > 390 ? "615px" : `${responsiveHeight}px`,
      width: "350px",
      top: "50%",
      left: "50%",
      right: "auto",
      zIndex: 1,
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };
  // iPhone8用の、モーダルスタイル
  const smallMobileModalStyles = {
    overlay: {
      top: 0,
      left: 0,
      zIndex: 999,
      backgroundColor: "rgba(0,0,0,0.4)",
    },
    content: {
      height: "480px",
      width: "350px",
      top: "50%",
      left: "50%",
      right: "auto",
      zIndex: 1,
      bottom: "auto",
      marginRight: "-50%",
      borderRadius: "5px",
      transform: "translate(-50%, -50%)",
      padding: "-20px!important",
      border: "none!important",
    },
  };
  const [modalIsOpen, setIsOpen] = useState(true);
  const closeModal = () => {
    // ボタン押下時の色を変えてすぐに元に戻す
    window.history.back();
    setIsOpen(false);
  };

  const responsiveModalStyles = () => {
    if (responsiveWidth > 1000) {
      return largeModalStyles;
    }
    if (responsiveWidth > 730) {
      return middleModalStyles;
    }
    if (responsiveHeight > 580) {
      return mobileModalStyles;
    }
    if (responsiveHeight < 580) {
      return smallMobileModalStyles;
    }

    return largeModalStyles;
  };

  return (
    <>
      <div style={{ width: "660px" }}>
        <Modal isOpen={modalIsOpen} style={responsiveModalStyles()}>
          <>
            <div
              style={{
                margin: "5px 15px 0px 0px",
                float: "right",
                cursor: "pointer",
                height: "25px",
                width: "25px",
                fontWeight: "bold",
              }}
              onClick={closeModal}
            >
              <p
                style={{
                  fontWeight: "bold",
                  margin: "10px 0 0 0",
                }}
              >
                ✕
              </p>
            </div>
            <div
              className="thanks-page"
              style={{ marginTop: responsiveWidth < 600 ? "-35px" : "0px" }}
            >
              <h1
                style={{
                  fontWeight: "bold",
                  fontSize:
                    responsiveHeight < 550
                      ? responsiveWidth < 600
                        ? "16px"
                        : "16px"
                      : responsiveWidth < 600
                      ? "17px"
                      : "19px",
                  marginTop:
                    responsiveHeight < 550
                      ? responsiveWidth < 600
                        ? "0px"
                        : "-80px"
                      : responsiveWidth < 600
                      ? "50px"
                      : "190px",
                }}
              >
                {decodedUserName}様
              </h1>
              <p>オンライン窓口をご利用いただき、有難うございました。</p>
            </div>
            <div
              style={{
                textAlign: "center",
              }}
            ></div>
          </>
        </Modal>
      </div>
    </>
  );
}

export default ThxPage;
