export const UnderLine = (props) => (
  <span
    style={{
      borderBottom: "0.14em solid #999999",
      display: "inline",
      padding: "0 4px",
      lineHeight: "1.4",
    }}
  >
    {props.children}
  </span>
);
